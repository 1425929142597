import { render, staticRenderFns } from "./GridCellResizer.vue?vue&type=template&id=1f71c299&scoped=true&"
import script from "./GridCellResizer.vue?vue&type=script&lang=js&"
export * from "./GridCellResizer.vue?vue&type=script&lang=js&"
import style0 from "./GridCellResizer.vue?vue&type=style&index=0&id=1f71c299&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f71c299",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f71c299')) {
      api.createRecord('1f71c299', component.options)
    } else {
      api.reload('1f71c299', component.options)
    }
    module.hot.accept("./GridCellResizer.vue?vue&type=template&id=1f71c299&scoped=true&", function () {
      api.rerender('1f71c299', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/Grid/Layout/Table/Cells/Resizer/GridCellResizer.vue"
export default component.exports